// src/EclipsePage.js
import React from 'react';
import './EclipsePage.css';
import BackButton from './components/BackButton';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const handleWorksClick = (e) => {
    e.preventDefault();
    navigate('/', { state: { scrollToWorks: true } });
  };

  return (
    <footer className="site-footer-2">
      <div className="footer-content-2">
        <div className="footer-section-2">
          <h3>CONTACT</h3>
          <p>shunueno@stanford.edu</p>
        </div>
        <div className="footer-section-2">
          <h3>CONNECT</h3>
          <ul className="social-links-2">
            <li><a href="https://linkedin.com/in/shunueno" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://instagram.com/_shun_ueno" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
        <div className="footer-section-2">
          <h3>PAGES</h3>
          <ul className="footer-nav-2">
            <li><a href="/">Home</a></li>
            <li><a href="/#works" onClick={handleWorksClick}>Works</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom-2">
        <p className="footer-name-2">SHUN UENO</p>
        <p className="footer-copyright-2">&copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}



function EclipsePage() {
  return (
    <div className="eclipse-page">
      <BackButton />
      <h1 className="eclipse-title">ECLIPSE</h1>
      <div className="gif-container">
        <img src="/eclipse2.gif" alt="Eclipse Animation" className="eclipse-gif" />
      </div>
      <div className="eclipse-content">
        <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">Figma, Illustrator, Photoshop</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Graphic Design, Poster Design</span>
            </div>
            <div className="project-detail">
              <span className="label">Summary:</span>
              <span className="value">Final project for DESIGN 170</span>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="eclipse-description">
          <p> Conceptualized the ECLIPSE poster series. Used Figma, Illustrator, and Photoshop to build style guides, branding motifs, and posters. 
            
            The ECLIPSE poster series has a simple mission: to cast a spotlight on individuals who typically do not grace the glossy pages of mainstream posters. Posters have traditionally featured musicians, celebrities, and athletes, serving as icons of popular culture. But what about the intellectuals, the trailblazers, and the innovators whose work shapes our world in quiet, yet impactful ways? The term "Eclipse" signifies the overshadowing of these significant but less recognized contributions by more mainstream figures. Think Nobel Prize winners, visionary politicians, pioneering scientists, and unsung heroes from various disciplines – they are the centers of this project. I hope to highlight these individuals in a fresh, engaging format and to establish an educational element that not only decorates a space but also informs and enlightens its audience.
</p>
          {/* Add more content as needed */}
          <hr className="divider" />
          <h2 className="poster-title">POSTERS</h2>
          <div className="image-text-section">
            <img src="/Marie.png" alt="Marie Curie" className="section-image image-1" />
            <p className="section-text text-1">Marie Curie</p>
          </div>

          <div className="image-text-section">
            <img src="/mission.png" alt="Mission" className="section-image image-2" />
            <p className="section-text text-2">Project Mission</p>
          </div>

          <div className="image-text-section">
            <img src="/starter.png" alt="Starter Collection" className="section-image image-3" />
            <p className="section-text text-3">Starter Collection</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  );
}


export default EclipsePage;