// src/PrescriptionPage.js
import React, { useState, useEffect } from 'react';
import './PrescriptionPage.css';
import BackButton from './components/BackButton';

const rotations = [0, 90, 180, 270];
const numberOfImages = 10;

function generateRandomPosition() {
    return {
        top: `${Math.random() * 90}%`,
        left: `${Math.random() * 90}%`,
        width: `${Math.random() * (20 - 5) + 5}%`, // Random size between 5% and 20%
    };
}

function getRandomRotation(currentRotation) {
    const availableRotations = rotations.filter(r => r !== currentRotation);
    return availableRotations[Math.floor(Math.random() * availableRotations.length)];
}

function PrescriptionPage() {
    const [imageStates, setImageStates] = useState(
        Array(numberOfImages).fill().map(() => ({
            rotation: 270,
            position: generateRandomPosition(),
        }))
    );
    const [showNice, setShowNice] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setImageStates(prevStates => 
                prevStates.map(state => ({
                    ...state,
                    rotation: getRandomRotation(state.rotation),
                }))
            );
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (imageStates.every(state => state.rotation === 270)) {
            setShowNice(true);
            const timer = setTimeout(() => {
                setShowNice(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [imageStates]);

    const handleImageClick = (index) => {
        setImageStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = {
                ...newStates[index],
                rotation: getRandomRotation(newStates[index].rotation),
            };
            return newStates;
        });
    };

    return (
        <div className='prescription-page'>
            <BackButton />
            
            <div className='image-container'>
                {imageStates.map((state, index) => (
                    <img 
                        key={index}
                        src='/c3.png'
                        alt={`Prescription ${index + 1}`}
                        className='prescription-image'
                        style={{
                            transform: `rotate(${state.rotation}deg)`,
                            top: state.position.top,
                            left: state.position.left,
                            width: state.position.width,
                        }}
                        onClick={() => handleImageClick(index)}
                    />
                ))}
            </div>
            {showNice && <div className="nice-text">Nice</div>}
        </div>
    );
}

export default PrescriptionPage;