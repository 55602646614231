// src/EclipsePage.js
import React from 'react';
import './MovieGamePage.css';
import BackButton from './components/BackButton';

function MovieGamePage() {
  return (
    <div className="movie-game-page">
        <BackButton />
        <h1 className="movie-game-title">Movie Game</h1>
      
      <div className="movie-game-content">
        <div className="movie-game-description">
          <p>Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.</p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
  );
}

export default MovieGamePage;