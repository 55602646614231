

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { useLocation } from 'react-router-dom';




function customScroll(element, options = {}) {
  const {
    initialSpeed = 1,
    finalSpeed = 20,
    acceleration = 0.2,
    fps = 60,
    targetPosition
  } = options;

  const startPosition = window.scrollY;
  let currentPosition = startPosition;
  let currentSpeed = initialSpeed;

  function scroll() {
    const distance = targetPosition - currentPosition;
    const direction = distance > 0 ? 1 : -1;

    if (Math.abs(distance) > 1) {
      currentPosition += currentSpeed * direction;
      currentSpeed = Math.min(currentSpeed + acceleration, finalSpeed);
      
      if ((direction > 0 && currentPosition > targetPosition) || 
          (direction < 0 && currentPosition < targetPosition)) {
        currentPosition = targetPosition;
      }
      
      window.scrollTo(0, currentPosition);
      setTimeout(() => requestAnimationFrame(scroll), 1000 / fps);
    } else {
      window.scrollTo(0, targetPosition);
    }
  }

  requestAnimationFrame(scroll);
}

function WorkCard({ imageSrc, title, description, isUnblurred, isHovered, link, index }) {
  const [cardHovered, setCardHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const currentRef = cardRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setIsVisible(true);
          }, index * 50);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [index]);

  return (
      <Link to={link} className="work-card-link">
        <div
          ref={cardRef}
          className={`work-card ${isUnblurred ? 'unblurred' : ''} ${isVisible ? 'visible' : ''}`}
          onMouseEnter={() => setCardHovered(true)}
          onMouseLeave={() => setCardHovered(false)}
        >
          <div className={`work-image-wrapper ${cardHovered || isUnblurred || isHovered ? 'unblurred' : ''}`}>
            <img src={imageSrc} alt={title} className="work-image" />
          </div>
          <div className={`work-info ${cardHovered || isUnblurred || isHovered ? 'unblurred' : ''}`}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </Link>
    );
}

function Footer() {
  const handleSmoothScroll = (e, targetId) => {
    e.preventDefault();

    if (targetId === 'top') {
      customScroll(document.documentElement, {
        initialSpeed: 50,
        finalSpeed: 100,
        acceleration: 1.5,
        targetPosition: 0
      });
    } else {
      const viewportHeight = window.innerHeight;
      customScroll(document.documentElement, {
        initialSpeed: 50,
        finalSpeed: 100,
        acceleration: 1.5,
        targetPosition: viewportHeight + 50
      });
    }
  };

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>CONTACT</h3>
          <p>shunueno@stanford.edu</p>
        </div>
        <div className="footer-section">
          <h3>CONNECT</h3>
          <ul className="social-links">
            <li><a href="https://linkedin.com/in/shunueno" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://instagram.com/_shun_ueno" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>PAGES</h3>
          <ul className="footer-nav">
          <li><a href="#top" onClick={(e) => handleSmoothScroll(e, 'top')}>Home</a></li>
            <li><a href="#works" onClick={(e) => handleSmoothScroll(e, 'works')}>Works</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p className="footer-name">SHUN UENO</p>
        <p className="footer-copyright">&copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}



function Home() {
  const [hoveredCircle, setHoveredCircle] = useState(null);

  const [homePagePercentage, setHomePagePercentage] = useState(100);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const percentage = Math.max(0, Math.min(100, (windowHeight - scrollPosition) / windowHeight * 100));
      setHomePagePercentage(percentage);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const isCursorInHomePage = (cursorY) => {
    const windowHeight = window.innerHeight;
    const homePageHeight = windowHeight * (homePagePercentage / 100);
    return cursorY <= homePageHeight;
  };
  
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  const [imageSrc, setImageSrc] = useState("/IMG_1387.JPG");
  
  const [visibleContent] = useState(null);
  const [showMobileImage] = useState(false);
  const [circleVisibility] = useState([false, false, false]);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
 
  const [resumeAnimated, setResumeAnimated] = useState(false);

  const [activeSection, setActiveSection] = useState('about');
  const [showResumeImage, setShowResumeImage] = useState(false);

  const showAboutOnMobileRef = useRef(false);

  const desktopAboutText = `Hi, I'm Shun! I am a software engineer and designer, double majoring in Computer Science and Design at Stanford University. Novelty in design and creativity in interaction are core foundations in my work. I aim to balance intuitive design with unique digital experiences for all users in a wide range of fields. I hope to combine my experience in backend and frontend development with my design background to advance digital products and experiences.
You can contact me at shunueno@stanford.edu by clicking the email icon you're currently hovering over!`;

const mobileAboutText = `Hi, I'm Shun! I am a software engineer and designer, double majoring in Computer Science and Design at Stanford University. Novelty in design and creativity in interaction are core foundations in my work. I aim to balance intuitive design with unique digital experiences for all users in a wide range of fields. I hope to combine my experience in backend and frontend development with my design background to advance digital products and experiences.
Reach out at shunueno@stanford.edu! ▼WORK`;

const updateShowAboutOnMobile = useCallback((value) => {
  showAboutOnMobileRef.current = value;
}, []);
useEffect(() => {
  if (window.innerWidth <= 768) {
    updateShowAboutOnMobile(true);
  }
}, [updateShowAboutOnMobile]);

  const handleHoverStateChange = useCallback((circleIndex, isHovered) => {
    // You can perform any action here based on the hover state change
    console.log(`Circle ${circleIndex} hover state: ${isHovered}`);
    // If you need to update some other state or perform side effects, do it here
  }, []);

  const handleSectionChange = (section) => {
    if (isMobile) {
      setActiveSection(section);
      setShowResumeImage(section === 'resume');
      // Hide about content when resume is shown
      if (section === 'resume') {
        updateShowAboutOnMobile(false);
      } else {
        updateShowAboutOnMobile(true);
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setActiveSection('about');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.state && location.state.scrollToWorks) {
      const viewportHeight = window.innerHeight;
      window.scrollTo({
        top: viewportHeight,
        behavior: 'smooth'
      });
      window.history.replaceState({}, document.title)
    }
  }, [location]);

  const [showCursorText, setShowCursorText] = useState(true);

  const CursorText = ({ text, position, visible }) => {
    const isInHomePage = isCursorInHomePage(position.y);
    
    if (!visible || !isInHomePage) return null;
    return (
      <div 
      className="cursor-text"
        style={{
          position: 'fixed',
          left: position.x + 20, // Offset from cursor
          top: position.y + 20,
          pointerEvents: 'none', // Ensures it doesn't interfere with other interactions
          zIndex: 9999,
          color: 'black',
          backgroundColor: '#E5E5E5',
          opacity: 0.7,
          padding: '3px 8px',
          borderRadius: '8px',
          fontSize: '15px',
          fontFamily: 'Helvetica, Arial, sans-serif', 
          fontWeight: 550
        }}
      >
        {text}
      </div>
    );
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
  
    if (!isMobile) {
      window.addEventListener('mousemove', handleMouseMove);
    }
  
    return () => {
      if (!isMobile) {
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [isMobile]);
  
  





  useEffect(() => {
    const updateImageSrc = () => {
      if (window.innerWidth < 768) {
        setImageSrc("/IMG_1387v2.JPG");
      } else {
        setImageSrc("/IMG_1387.JPG");
      }
    };

    updateImageSrc();
    window.addEventListener('resize', updateImageSrc);
    return () => window.removeEventListener('resize', updateImageSrc);
  }, []);

  const [circles, setCircles] = useState(() => {
    const isMobile = window.innerWidth <= 768;
    
    if (isMobile) {
      // Fixed positions for mobile
      return [
        { x: window.innerWidth - 21 * 0.01 * window.innerWidth, y: window.innerHeight - 21 * 0.01 * window.innerWidth, dx: 0, dy: 0 },
        { x: window.innerWidth - 39 * 0.01 * window.innerWidth, y: window.innerHeight - 39 * 0.01 * window.innerWidth, dx: 0, dy: 0 },
        { x: window.innerWidth - 51 * 0.01 * window.innerWidth, y: window.innerHeight - 51 * 0.01 * window.innerWidth, dx: 0, dy: 0 }
      ];
    } else {
      // Random positions for desktop (existing code)
      return Array.from({ length: 3 }, () => {
        const circleRadius = getCircleSize() / 2;
        return {
          x: Math.random() * (window.innerWidth - 2 * circleRadius) + circleRadius,
          y: Math.random() * (window.innerHeight - 2 * circleRadius) + circleRadius,
          dx: (Math.random() - 0.5) ,
          dy: (Math.random() - 0.5) 
        };
      });
    }
  });



const moveCircles = useCallback(() => {
  if (window.innerWidth > 768) {
  function checkCollision(circle1, circle2) {
    const dx = circle1.x - circle2.x;
    const dy = circle1.y - circle2.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const minDistance = getCircleSize(); // Use the dynamic size
    if (distance < minDistance) {
      return {
        collided: true,
        overlap: minDistance - distance,
        nx: dx / distance,
        ny: dy / distance
      };
    }
    return { collided: false };
  }

  const moveCircle = (circle, index, allCircles) => {
    if (circle.isHovered) return circle;

    let { x, y, dx, dy } = circle;
    const circleRadius = getCircleSize() / 2;

    // Check for collisions with other circles
    allCircles.forEach((otherCircle, otherIndex) => {
      if (index !== otherIndex) {
        const collision = checkCollision(circle, otherCircle);
        if (collision.collided) {
          // Separate the circles
          const separation = collision.overlap / 2;
          x += collision.nx * separation;
          y += collision.ny * separation;
          otherCircle.x -= collision.nx * separation;
          otherCircle.y -= collision.ny * separation;

          // Calculate new velocities
          const dotProduct = dx * collision.nx + dy * collision.ny;
          dx -= 2 * dotProduct * collision.nx;
          dy -= 2 * dotProduct * collision.ny;

          const otherDotProduct = otherCircle.dx * -collision.nx + otherCircle.dy * -collision.ny;
          otherCircle.dx -= 2 * otherDotProduct * -collision.nx;
          otherCircle.dy -= 2 * otherDotProduct * -collision.ny;
        }
      }
    });

    // Check for wall collisions
    if (x + dx > window.innerWidth - circleRadius) {
      x = window.innerWidth - circleRadius;
      dx = -dx;
    } else if (x + dx < circleRadius) {
      x = circleRadius;
      dx = -dx;
    }

    if (y + dy > window.innerHeight - circleRadius) {
      y = window.innerHeight - circleRadius;
      dy = -dy;
    } else if (y + dy < circleRadius) {
      y = circleRadius;
      dy = -dy;
    }

    x += dx;
    y += dy;

    return { ...circle, x, y, dx, dy };
  };

  setCircles(prevCircles => {
    const newCircles = prevCircles.map((circle, index) => moveCircle(circle, index, prevCircles));
    return newCircles;
  });

}
}, []);

  useEffect(() => {
    let animationId;
    
    const animate = () => {
      moveCircles();
      animationId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationId);
    };
  }, [moveCircles]);

  

  const handleCircleMouseEnter = (index) => {
    if (window.innerWidth > 768) {
      if (index === 1) {
        setResumeAnimated(true);
      }
      const setterFunction = setCircles;
      setterFunction(prevCircles => prevCircles.map((circle, i) => 
        i === index ? { ...circle, isHovered: true } : circle
      ));
      
      setHoveredCircle(index + 1);
      handleHoverStateChange(index + 1, true);
      setShowCursorText(false); // Hide cursor text when hovering over a circle
    }
  };
  
  const handleCircleMouseLeave = (index) => {
    if (window.innerWidth > 768) {
      if (index === 1) {
        setResumeAnimated(false);
      }
      const setterFunction = setCircles;
      setterFunction(prevCircles => prevCircles.map((circle, i) => 
        i === index ? { ...circle, isHovered: false } : circle
      ));
      
      setHoveredCircle(null);
      handleHoverStateChange(index + 1, false);
      setShowCursorText(true); // Show cursor text when unhovering from a circle
    }
  };
  

  function getCircleSize() {
    return window.innerWidth * 0.09; // 9% of viewport width
  }
  useEffect(() => {
    const imagesToPreload = [
      '/emaill.png',
      '/biggerlink.png',
      '/biggerdown.png',
      '/all3.png',
      '/gd2.png',
      '/writing3.png',
      '/sw.png',
      '/IMG_1387v2.JPG',
      '/IMG_1387.JPG',
      '/resume2.png'
    ];
  
    imagesToPreload.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);
useEffect(() => {
  function handleResize() {
    const circleRadius = getCircleSize() / 2;
    setCircles(prevCircles => prevCircles.map(circle => ({
      ...circle,
      x: Math.min(Math.max(circle.x, circleRadius), window.innerWidth - circleRadius),
      y: Math.min(Math.max(circle.y, circleRadius), window.innerHeight - circleRadius)
    })));
    
  }

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const getCirclePosition = (index) => {
    if (isMobile) {
      
    }
    return {}; // Return empty object for desktop layout
  };

  const copyEmailToClipboard = (e) => {
    e.preventDefault();
    const email = "shunueno@stanford.edu";
    navigator.clipboard.writeText(email).then(() => {
      // Optionally, you can show a notification or tooltip here
      alert("Email copied to clipboard!");
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  

  const renderCircle = (circle, index) => {
    if (isMobile) {
      return (
        <div
          key={index}
          className={`circle circle${index + 1} 
            ${visibleContent !== null ? (visibleContent === index + 1 ? 'active' : 'inactive') : ''}
            ${circleVisibility[index] ? 'visible' : ''}`}
          style={{
            ...getCirclePosition(index),
            transition: 'opacity 0.5s ease, transform 0.3s ease, box-shadow 0.3s ease'
          }}
          
        >
          
        
        </div>
      );
    } else {
      return (
        <div
          key={index}
          className={`circle circle${index + 1} ${hoveredCircle === index + 1 ? 'hovered' : ''}`}
          style={{
            transform: `translate(${circle.x}px, ${circle.y}px)`,
            transition: circle.isHovered ? 'none' : 'transform 0.1s linear'
          }}
          onMouseEnter={() => handleCircleMouseEnter(index)}
          onMouseLeave={() => handleCircleMouseLeave(index)}
        >
          {index === 0 && (
  <div onClick={copyEmailToClipboard} style={{ cursor: 'pointer' }}>
    <img src="whiteemail.png" alt="Email Icon" className="icon-image" />
  </div>
)}
          {index === 1 && (
            
            
              <img src="/whitelink.png" alt="Resume Icon" className="icon-image-3" />
          
          )}
          {index === 2 && (
            <div
              className="icon-wrapper"
              onClick={(e) => {
                e.preventDefault();
                const viewportHeight = window.innerHeight;
                customScroll(document.documentElement, {
                  initialSpeed: 30,
                  finalSpeed: 100,
                  acceleration: 0.5,
                  targetPosition: viewportHeight
                });
              }}
            >
              <img src="/white_down.png" alt="Scroll Down Icon" className="icon-image-2" />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div>
     

     {!isMobile && (
  <CursorText 
    text="Hover circles" 
    position={mousePosition}
    visible={showCursorText}
  />
)}

      <header className="App-header">
      <div className="gradient-background"></div>


      {isMobile && (
  <div className="mobile-headers">
    <h1 
      className={`mobile-header ${activeSection === 'about' ? 'active' : ''}`}
      onClick={() => handleSectionChange('about')}
    >
      ABOUT
    </h1>
    <h1 
      className={`mobile-header ${activeSection === 'resume' ? 'active' : ''}`}
      onClick={() => handleSectionChange('resume')}
    >
      RESUME
    </h1>
  </div>
)}
        <div className="circle-container">
        {circles.map((circle, index) => renderCircle(circle, index))}
        </div>
   
        <div className={`blurred-text blurred-text1 ${(hoveredCircle === 1 || visibleContent === 1 || (isMobile && activeSection === 'about')) ? 'unblurred subtle-shift-left' : ''}`}
     style={{ display: isMobile && showResumeImage ? 'none' : 'block' }}>
  <h1 className="blurred-header header1">ABOUT</h1>
  
  <p className="blurred-paragraph paragraph1">
    {isMobile ? mobileAboutText : desktopAboutText}
  </p>
</div>

        {isMobile && (
  <div className={`mobile-resume-image ${showResumeImage ? 'visible' : ''}`}>
    <img src="/resume2.png" alt="Resume" className="resume-image-content" />
  </div>
)}

<div className={`blurred-text blurred-text2 ${hoveredCircle === 2 || visibleContent === 2 ? 'unblurred subtle-shift-left' : ''}`}>
  <h1 className="blurred-header header2">RESUME</h1>
</div>

<div className={`blurred-text blurred-text2 ${hoveredCircle === 2 || visibleContent === 2 ? 'unblurred subtle-shift-left' : ''}`}>
          <h1 className="blurred-header header2">RESUME</h1>
          {/*<p className="blurred-paragraph resume-message" > (Click the file icon to see full resume)</p>*/}
        </div>

        <div className={`resume-content`}>
  {/* Job 1 */}
  <div className={`resume-card ${resumeAnimated ? 'animated' : ''}`}>
    <h3>VALUENEX</h3>
    <p>Data Analyst/Software Engineering Intern</p>
    <ul>
      <li>Implemented Natural Language Processing and Cluster Mapping programs to provide predictive analytics and data visualization to clients</li>
      <li>Designed and developed AI-based solutions for multi-billion dollar corporations in the manufacturing industry</li>
    </ul>
  </div>

  {/* Job 2 */}
  <div className={`resume-card ${resumeAnimated ? 'animated' : ''}`}>
    <h3>Prokids</h3>
    <p>Software Engineering Intern</p>
    <ul>
      <li>OpenAI Integration in education technology</li>
      <li>Developed a scalable program that performs comprehensive analysis on programming lessons</li>
      <li>Collaborated with cross-functional teams to design and implement new features</li>
    </ul>
  </div>

  {/* Job 3 */}
  <div className={`resume-card ${resumeAnimated ? 'animated' : ''}`}>
    <h3>University of Tsukuba</h3>
    <p>Research Assistant</p>
    <ul>
      <li>Contributed to the University of Tsukuba's robot rehabilitation research, studying interdisciplinary robotics design</li>
    </ul>
  </div>

  {/* Education */}
  <div className={`resume-card ${resumeAnimated ? 'animated' : ''}`}>
    <h2>Education</h2>
    <p>Stanford University</p>
    <ul><li>Bachelor of Science in Computer Science and Design</li></ul>
  </div>

  {/* Skills */}
  <div className={`resume-card ${resumeAnimated ? 'animated' : ''}`}>
    <h2>Skills</h2>
    <ul>
      <li>ML, NLP, Java, Python, C, C++, JavaScript, React, Flask, Data Analysis, Consulting</li>
      <li>Design, Figma, MS Office (Word, Excel, PowerPoint, Outlook), Research, Communication</li>
      <li>Native Fluency in English and Japanese</li>
    </ul>
  </div>

</div>



<div className={`blurred-text blurred-text3 ${hoveredCircle === 3 || visibleContent === 3 ? 'unblurred subtle-shift-left' : ''}`}>
  <h1 className="blurred-header header3">▼WORK</h1>
</div>

<div className={`blurred-image blurred-image1 ${hoveredCircle === 2 || visibleContent === 2 ? 'unblurred' : ''}`}>
  {/* Removed resume image, replaced with textual resume content */}
</div>

<div className={`blurred-image blurred-image2 ${hoveredCircle === 1 || visibleContent === 1 || (isMobile && activeSection === 'about')? 'unblurred' : ''}`}>
  <img src={imageSrc} alt="Head shot" className="image-content" />
</div>

        {/* New mobile-only image */}
        {window.innerWidth <= 768 && (
          <div 
            className={`mobile-only-image ${showMobileImage ? 'visible' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              const viewportHeight = window.innerHeight;
              customScroll(document.documentElement, {
                initialSpeed: 30,
                finalSpeed: 100,
                acceleration: 0.5,
                targetPosition: viewportHeight
              });
              
            }}
          >
            <img src="/white_down.png" alt="Mobile Only" className="mobile-image-content" />
          </div>
        )}
      </header>
      <section id="works" className="works-section">
        
        <div className="works-container">
          {[
            { imageSrc: "/radar2.png", title: "VALUENEX", description: "ML and NLP software engineering in data consulting", filter: "Software Development", link: "/works/valuenex" },
            { imageSrc: "/pwr.png", title: "Hostile Architecture and Inaccessibility", description: "Lunsford Award nominated presentation and essay", filter: "Writing", link: "/works/hostile-architecture" },
            { imageSrc: "/eclipse_blur.png", title: "ECLIPSE", description: "Poster series of hidden heroes", filter: "Graphic Design", link: "/works/eclipse" },
            { imageSrc: "/rockpath2.png", title: "Just for Fun", description: "A Collection of Ideas For the Bored Mind", filter: "Software Development", link: "/works/justforfun"},
            { imageSrc: "/ntgame.png", title: "Actor to Actor", description: "Webgame inspired by the 6 degrees of Kevin Bacon theory", filter: "Software Development" },
            { imageSrc: "/frag2.png", title: "fragrance.ai", description: "Visual exploration of fragrances using NLP", filter: "Software Development" },
          ].map((card, index) => (
            <WorkCard
              key={card.title}
              imageSrc={card.imageSrc}
              title={card.title}
              description={card.description}
              isUnblurred={true}
              isHovered={false
              }
              link={card.link}
              index={index}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;