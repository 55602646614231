// src/components/BackButton.js
import React from 'react';
import './BackButton.css';

function BackButton() {
  const handleClick = () => {
    window.history.back();
  };

  return (
    <button className="back-button" onClick={handleClick}>
        <img src="/white_back.png" alt="Back" className="back-arrow-icon" />
    </button>
  );
}

export default BackButton;
