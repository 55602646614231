// src/EclipsePage.js
import React from 'react';
import './FragrancePage.css';
import BackButton from './components/BackButton';

function FragrancePage() {
  return (
    <div className="fragrance-ai-page">
        <BackButton />
        <h1 className="fragrance-ai-title">fragrance.ai</h1>
      
      
      <div className="fragrance-ai-content">
        <div className="fragrance-ai-description">
          <p>Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.Description of the Eclipse project goes here.</p>
          {/* Add more content as needed */}
        </div>
      </div>
    </div>
  );
}

export default FragrancePage;