// src/VNPage.js
import React from 'react';
import './VNPage.css';
import BackButton from './components/BackButton';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const handleWorksClick = (e) => {
    e.preventDefault();
    navigate('/', { state: { scrollToWorks: true } });
  };

  return (
    <footer className="site-footer-4">
      <div className="footer-content-4">
        <div className="footer-section-4">
          <h3>CONTACT</h3>
          <p>shunueno@stanford.edu</p>
        </div>
        <div className="footer-section-4">
          <h3>CONNECT</h3>
          <ul className="social-links-4">
            <li><a href="https://linkedin.com/in/shunueno" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://instagram.com/_shun_ueno" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
        <div className="footer-section-4">
          <h3>PAGES</h3>
          <ul className="footer-nav-4">
            <li><a href="/">Home</a></li>
            <li><a href="/#works" onClick={handleWorksClick}>Works</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom-4">
        <p className="footer-name-4">SHUN UENO</p>
        <p className="footer-copyright-4">&copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}

function VNPage() {
  return (
    <div className="vn-page">
        <BackButton />
        <h1 className="vn-title">VALUENEX</h1>
        <div className="vn-image-container">
        <img src="/radar.png" alt="VN RADAR" className="vn-image" />
      </div>
      <div className="vn-content">
      <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">2023 - Present</span>
            </div>
            <div className="project-detail">
              <span className="label">Tools:</span>
              <span className="value">NLP, ML, Python</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Data Analysis, Consulting</span>
            </div>
            <div className="project-detail">
              <span className="label">Role:</span>
              <span className="value">Software Engineer and Data Analyst Intern</span>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="vn-description">
          <p>Software Engineering and Data Analyst internship at{' '}
    <a 
      href="https://en.valuenex.com/" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      VALUENEX, Inc
    </a>
    {''}. Main contributions include implementing Natural Language Processing and Cluster Mapping programs to provide predictive analytics and big data visualization to C-level clients. Additionally, I led a project involving the development of Al-based process optimization programs for multi-billion dollar corporations in the manufacturing industry.</p>
          {/* Add more content as needed */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VNPage;