// src/HostileArchitecturePage.js
import React, { useState } from 'react';
import './HostileArchitecturePage.css';
import BackButton from './components/BackButton';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const handleWorksClick = (e) => {
    e.preventDefault();
    navigate('/', { state: { scrollToWorks: true } });
  };

  return (
    <footer className="site-footer-3">
      <div className="footer-content-3">
        <div className="footer-section-3">
          <h3>CONTACT</h3>
          <p>shunueno@stanford.edu</p>
        </div>
        <div className="footer-section-3">
          <h3>CONNECT</h3>
          <ul className="social-links-3">
            <li><a href="https://linkedin.com/in/shunueno" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="https://instagram.com/_shun_ueno" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
        <div className="footer-section-3">
          <h3>PAGES</h3>
          <ul className="footer-nav-3">
            <li><a href="/">Home</a></li>
            <li><a href="/#works" onClick={handleWorksClick}>Works</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom-3">
        <p className="footer-name-3">SHUN.U</p>
        <p className="footer-copyright-3">&copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}

function HostileArchitecturePage() {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="hostile-architecture-page">
        <BackButton />
      <h1 className="hostile-architecture-title">Hostile Architecture and Inaccessibility</h1>
      <div className="media-container">
        {showVideo ? (
          <iframe
            src="https://www.youtube.com/embed/9CyK23N3yv4?si=FvAdT7jTlAUPNA5R"
            title="Hostile Architecture and Inaccessibility"
            className="work-video"
            allowFullScreen
          ></iframe>
        ) : (
          <img src="/pwr.png" alt="Hostile Architecture and Inaccessibility" className="work-imageh" />
        )}
        <button 
          className="toggle-media-btn"
          onClick={() => setShowVideo(!showVideo)}
        >
          {showVideo ? 'Show Image' : 'Show Video'}
        </button>
      </div>
      <div className="embed-container">
        <iframe
          src="https://drive.google.com/file/d/1utiZmNUBtjK8pPZU7cUjrHroaYl-pQfr/preview"
          title="Hostile Architecture Document"
          className="embed-iframe"
          allowFullScreen
        ></iframe>
      </div>
      <hr className="divider" />
      <div className="hostile-architecture-content">
      <div className="project-info-container">
          <div className="project-info">
            <div className="project-detail">
              <span className="label">Year:</span>
              <span className="value">2024</span>
            </div>
            <div className="project-detail">
              <span className="label">Disciplines:</span>
              <span className="value">Research, Argumentative Writing</span>
            </div>
            <div className="project-detail">
              <span className="label">Summary:</span>
              <span className="value">Final research paper for PWR2RL </span>
            </div>
          </div>
        </div>
        </div>
        <Footer />
    </div>
  );
}

export default HostileArchitecturePage;